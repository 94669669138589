/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
// import jQuery from 'jquery';
// window.$ = jQuery;

// window.$ = require('jquery');

// window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

var coupon = function () {
    var getListUser = function () {
        var agency_id = $('#agency_id').children("option:selected").val();
        $('#agency_id').on('change', function () {
            agency_id = $(this).children("option:selected").val();
        })

        $('#btn').on('change', function () {
            var number = $(this).find('option:selected').attr('data-number');
            if (number === '2') {
                var data = {agency_id: agency_id}
                callAjaxUser($(this).data('url'), data)
            } else {
                $('#get_data').html('')
            }
        })
    }
    // var getProduct = function () {
    //     $('#choice-product').on('change', function () {
    //         if ($(this).val() == 1) {
    //             console.log($(this).val())
    //             callAjaxProduct($(this).data('url'))
    //         }
    //         if ($(this).val() == 0) {
    //             $('#data-product').html('')
    //         }
    //     })
    // }

    var callAjaxUser = function (url, data) {
        $.ajax({
            type: 'GET',
            dataType: "json",
            url: url,
            data: data,
            success: function (res) {
                $('#get_data').html(res.html)
            }
        });
    }

    // var callAjaxProduct = function (url, data) {
    //     $.ajax({
    //         type: 'GET',
    //         dataType: "json",
    //         url: url,
    //         data: data,
    //         success: function (res) {
    //             $('#data-product').html(res.html)
    //             console.log(res)
    //         }
    //     }).fail(function (err) {
    //         console.log(err)
    //     });
    // }

    var changePageUserList = function () {
        $('#get_data').on('click', '.pagination a', function (e) {
            e.preventDefault()
            callAjaxUser($(this).attr('href'), {})
            return false
        })
    }

    // var changePageProductList = function () {
    //     $('#data-product').on('click', '.pagination a', function (e) {
    //         e.preventDefault()
    //         callAjaxProduct($(this).attr('href'), {})
    //         return false
    //     })
    // }

    var filterUser = function () {
        $('#get_data').on('click', '#apply', function (e) {
            var data = {
                agency_id: $('#agency_id').val(),
                name: $('#get_data #name').val(),
                email: $('#get_data #email').val(),
            }
            callAjaxUser($('#btn').data('url'), data)
            return false
        })
    }

    // var filterProduct = function () {
    //     $('#data-product').on('click', '#apply-product', function (e) {
    //         var data = {
    //             title: $('#title').val(),
    //             category_id: $('#category_id').val()
    //         }
    //         callAjaxProduct($('#choice-product').data('url'), data)
    //         return false
    //     })
    // }

    // var dropDownSearch = function () {
    //     $("#agency_id").select2({
    //         tags: "true",
    //         placeholder: "代理店",
    //         // allowClear: true,
    //     });
    // }
    var checkUser = function () {
        $('#get_data').on('click', '#check_all_user', function () {
            $('.get_user').not(this).prop('checked', this.checked);
        })

        $('.get_user').change(function () {
            $('#check_all_user').prop('checked', $('.get_user').not(':checked').length === 0)
        })
    }

    // var checkProduct = function () {
    //     $('#data-product').on('click', '#check_all_product', function () {
    //         $('.get_product').not(this).prop('checked', this.checked);
    //     })

    //     $('.get_product').change(function () {
    //         $('#check_all_product').prop('checked', $('.get_product').not(':checked').length === 0)
    //     })
    // }
    return {
        init: function () {
            getListUser()
            // getProduct()
            // dropDownSearch()
            changePageUserList()
            // changePageProductList()
            filterUser()
            // filterProduct()
            checkUser()
            // checkProduct()
        }
    }
}()

var Main = function () {
    return {
        coupon: function () {
            coupon.init()
        }
    }
}()


window.Main = Main
module.Main = Main
